 <template>
  <section id="">
    <b-row class="match-height">
      <b-col
          xl="12"
          md="12"
      >
<!--        <h2 class="text-center font-weight-bold">Welcome To</h2>-->
        <div class="text-center font-weight-bold">
          <!-- <span class="text-primary font-large-2">B</span>iyer
          <span class="text-primary font-large-2">J</span>uti
          <span class="text-primary font-large-2">A</span>dmin -->  
            <logo-dark class="text-center" style="height: 120px;"/>
            <div class="logo-summary-gap">
              <div class="summary-container">
                <div class="summary-content">
                  <p class="title">Lead</p>
                  <p class="value">{{ summary.Leads }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">OTP Verified</p>
                  <p class="value">{{ summary.OTP_verified }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Active User</p>
                  <p class="value">{{ summary.active_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Pending User</p>
                  <p class="value">{{ summary.pending_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Male User</p>
                  <p class="value">{{ summary.male_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Female User</p>
                  <p class="value">{{ summary.female_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Match</p>
                  <p class="value">{{ summary.matches }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Deleted User</p>
                  <p class="value">{{ summary.deleted_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Free User</p>
                  <p class="value">{{ summary.free_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Premium User</p>
                  <p class="value">{{ summary.premium_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">Android User</p>
                  <p class="value">{{ summary.android_users }}</p>
                </div>
                <div class="summary-content">
                  <p class="title">IOS User</p>
                  <p class="value">{{ summary.ios_users }}</p>
                </div>
              </div>
            </div>
          </div>

        <!-- <lottie-animation
            ref="anim"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :animationData="require('@/assets/dashmain.json')"
            class="text-center h-50"
        /> -->

<!--        <b-row class="match-height">-->

<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="primary"-->
<!--                icon="ShoppingBagIcon"-->
<!--                :statistic="statistics.total_order_count"-->
<!--                :statistic-amount="statistics.total_order_amount"-->
<!--                statistic-title="Total Orders"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->

<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="warning"-->
<!--                icon="ArchiveIcon"-->
<!--                :statistic="statistics.pending_order_count"-->
<!--                :statistic-amount="statistics.pending_order_amount"-->
<!--                statistic-title="Pending Orders"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="info"-->
<!--                icon="SendIcon"-->
<!--                :statistic="statistics.shipped_order_count"-->
<!--                :statistic-amount="statistics.shipped_order_amount"-->
<!--                statistic-title="Shipped Orders"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                hide-chart-->
<!--                color="success"-->
<!--                icon="TruckIcon"-->
<!--                :statistic="statistics.delivered_order_count"-->
<!--                :statistic-amount="statistics.delivered_order_amount"-->
<!--                statistic-title="Delivered Orders"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--        </b-row>-->
      </b-col>
    </b-row>
<!--    <b-row class="match-height">-->
<!--      <b-col-->
<!--          xl="12"-->
<!--          md="12"-->
<!--      >-->
<!--        <b-row class="match-height">-->

<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="info"-->
<!--                icon="DollarSignIcon"-->
<!--                :statistic="'৳ '+ statistics.total_earning"-->
<!--                statistic-title="Total Earning"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="primary"-->
<!--                icon="HeartIcon"-->
<!--                :statistic="'৳ '+ statistics.received_amount"-->
<!--                statistic-title="Received Amount"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="success"-->
<!--                icon="AwardIcon"-->
<!--                :statistic="'৳ '+ statistics.current_balance"-->
<!--                statistic-title="Current Balance"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                hide-chart-->
<!--                color="warning"-->
<!--                icon="Navigation2Icon"-->
<!--                :statistic="'৳ '+ statistics.requested_withdrawal"-->
<!--                statistic-title="Requested Withdrawal"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-col>-->
<!--    </b-row>-->
<!--    <b-row class="match-height">-->
<!--      <b-col-->
<!--          xl="12"-->
<!--          md="12"-->
<!--      >-->
<!--        <b-row class="match-height">-->

<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="primary"-->
<!--                icon="GiftIcon"-->
<!--                :statistic="statistics.total_products"-->
<!--                statistic-title="Total Products"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="success"-->
<!--                icon="CheckCircleIcon"-->
<!--                :statistic="statistics.active_products"-->
<!--                statistic-title="Active Products"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                color="warning"-->
<!--                icon="AlertCircleIcon"-->
<!--                :statistic="statistics.low_stock"-->
<!--                statistic-title="Low Stock"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--          <b-col-->
<!--              xl="3"-->
<!--              md="4"-->
<!--              sm="6"-->
<!--          >-->
<!--            <statistic-card-vertical-->
<!--                hide-chart-->
<!--                color="danger"-->
<!--                icon="FrownIcon"-->
<!--                :statistic="statistics.out_of_stock"-->
<!--                statistic-title="Out of Stock"-->
<!--                :loading="totalError||totalLoading"-->
<!--            />-->
<!--          </b-col>-->
<!--        </b-row>-->
<!--      </b-col>-->
<!--    </b-row>-->
  </section>
</template>

<script>
  import { BRow, BCol, BSpinner } from 'bootstrap-vue'
  import EcommerceMedal from './EcommerceMedal.vue'
  import EcommerceStatistics from './EcommerceStatistics.vue'
  import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
  import EcommerceOrderChart from './EcommerceOrderChart.vue'
  import EcommerceProfitChart from './EcommerceProfitChart.vue'
  import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
  import CardStatistic from "@/views/card/card-statistic/CardStatistic";
  import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
  import {mapState} from "vuex";
  import LogoDark from "@core/layouts/components/logo-dark";
  import LottieAnimation from 'lottie-web-vue'

  export default {
    components: {
      BRow,
      BCol,
      LogoDark,
      EcommerceMedal,
      EcommerceStatistics,
      EcommerceRevenueReport,
      EcommerceOrderChart,
      EcommerceProfitChart,
      EcommerceEarningsChart,
      CardStatistic,
      StatisticCardVertical,
      BSpinner,
      LottieAnimation,
    },
    data() {
      return {
        data: {},
      }
    },
    computed:{
      // ...mapState('dashboard', ["totalError","totalLoading","statistics", "submitError","submitSuccess","submitLoading"]),
      ...mapState('summary', ["summary","summaryLoading","submitError"]),

    },
    beforeCreate() {
      this.$store.dispatch('summary/fetchSummary');
    },
    created() {
    },
  }
</script>

<style scoped>
  /* // @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  // @import '@core/scss/vue/libs/chart-apex.scss'; */

  .logo-summary-gap {
    margin-top: 20px;
  }

  .summary-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 1rem;
    justify-items: center;
  }

  .summary-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: #fceaea;
    border-radius: 0.5rem;
  }

  .title {
    padding-top: 15px;
  }

  .value {
    font-size: x-large;
    color: #b33434;
  }
</style>
